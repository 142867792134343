.messagesHeader {
  @apply px-3 py-2 border-0 border-solid border-b border-gray-200 flex flex-row items-center;
}

.statusIndicator {
  @apply absolute rounded-full w-4 h-4;
  top: -0.125rem;
  right: -0.125rem;
}

@primary-color: #2F348D;@secondary-color: #167ED0;@link-color: #2F348D;