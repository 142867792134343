@import '~antd/dist/antd.less';

@tailwind components;
@tailwind utilities;

@secondary-color: #167ed0;

/** BODY **/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** FONTS **/
a {
  text-decoration: underline;
  font-weight: 600;
}

h1 {
  font-size: 26px;
  font-weight: 600;
}

h2 {
  font-size: 22px;
  font-weight: 500;
}

.h-full {
  height: 100%;
}

/** MISC **/
.ant-layout-sider-trigger {
  background-color: @primary-color;
}

/** BACKGROUND **/
.bg-primary {
  background-color: @primary-color !important;
}

.bg-white {
  background-color: white !important;
}

/** Buttons **/
button[type='button'] {
  background-color: none;
}

div .ant-upload-list.ant-upload-list-text{
  max-height: none;
}

/** Forms **/
.ant-input-focused {
  
}

//** Uploaders **/
.ant-upload-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.ant-upload.ant-upload-drag {
  width: 100%;
}

.ant-upload-list.ant-upload-list {
  max-width: 12rem;
  margin: 0px;
  max-height: 5rem;
  overflow: scroll;
  text-overflow: ellipsis;
}
@primary-color: #2F348D;@secondary-color: #167ED0;@link-color: #2F348D;