.floatingChatMenu {
  @apply fixed right-5 bottom-5 z-20 flex flex-col items-end justify-end;
}

.chatPopup {
  @apply static bg-white rounded-md shadow-md border border-solid border-gray-200 flex flex-col;
  width: 350px;
}

.floatingChatButton {
  @apply flex flex-row justify-center items-center w-12 h-12 hover:bg-primary-50 mt-3;
}

.notification {
  top: -5px;
  left: -5px;
  font-weight: bold;
  position: absolute;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: rgb(240, 21, 21);
}

@primary-color: #2F348D;@secondary-color: #167ED0;@link-color: #2F348D;