@import 'antd/dist/antd.less';

.logo {
  margin-left: auto;
  margin-right: auto;
  width: 140px;
  margin: 0 0 0 0;
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 61px;
  width: 200px;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider-trigger {
  background-color: white !important;
  svg {
    color: gray;
  }
}

.ant-menu-item {
  color: black;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #167ed0 !important;
}

.ant-menu-title-content {
  color: rgb(235, 235, 235);
}

.ant-menu-item-icon {
  font-size: x-large;
}

.ant-menu-item-selected {
  background-color: @primary-color !important;
  color: white !important;
  a {
    color: white !important;
  }
}

@primary-color: #2F348D;@secondary-color: #167ED0;@link-color: #2F348D;